:root {
    --colorPrimary: '';
    --colorSecondary: '';
    --colorPanels:'';
    --colorMenu: '';
    --colorTextPrimary: '';
    --colorTextSecondary: '';
    --colorTextPanels: '';
    --colorTextMenu: '';
    --colorPrimaryOpacity: '';

    --default-colorPrimary:rgb(169, 0, 255);
    --default-colorSecondary:rgb(77, 77, 77);
    --default-colorPanels:rgba(255, 255, 255,1);
    --default-colorMenu:rgb(247, 249, 252);
    --default-colorTextPrimary:rgb(255, 255, 255);
    --default-colorTextSecondary:rgb(255, 255, 255);
    --default-colorTextPanels:rgba(143, 155, 179,1);
    --default-colorTextMenu:rgb(34, 43, 69);
    --default-colorPrimaryOpacity:rgba(169, 0, 255, 0.08);
}