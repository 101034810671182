@import '../customization.css';

.template-tabs .ant-tabs-bar .ant-tabs-top-bar .ant-tabs-card-bar {
  margin-top: 0;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
  background: linear-gradient(45deg, var(--colorPanels), var(--colorPanels));
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab{
  background: linear-gradient(45deg, var(--colorPanels), var(--colorPanels));
}