
.ant-row.ant-form-item {
  margin: 0;
}


/* For tablets: */
@media only screen and (min-width: 600px) {
}


/* For desktop: */
@media only screen and (min-width: 768px) {
}