

.ant-radio-group .signature-option {
  background-color: #2288c2;
  border-radius: 50px !important;
  border: none;
  color: #FFFFFF;
  margin: 10px;
}

.ant-radio-group .signature-option:hover,
.ant-radio-group .signature-option.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #17577e;
  color: #FFFFFF;
}

/*OPTION BUTTONS*/

.signature-wizard .options-btn {
  background-color: #8a8a8a;
  border: none;
}

.signature-wizard .pending-files-btn {
  background-color: #acb4b6;
  border: none;
}

.ant-alert {
  background-color: #f8f8f8;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: #9a9a9a;
  font-size: 15px;
}

/*STEPS*/

.signature-wizard .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #8a8a8a;
  border: none;
}

.signature-wizard .ant-steps-item-wait .ant-steps-item-icon span {
  color: #FFFFFF;
}

.signed-wizard .ant-steps-item-process .ant-steps-item-icon {
  background-color: #2288c2;
}