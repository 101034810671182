@import "../../../Helpers/customization.css";

.ant-dropdown-menu-item{
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  color: var(--colorTextMenu);
  font-size: 15px;
}

.margin-left-drop-icon{
  margin-left: 9px;
}

.sider-menu{
  width: 100%;
  text-align: right;
}

.header-logo{
  max-height: 40px;
}