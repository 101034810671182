.text-title {
    font-weight: 700 !important;
}

.size10 {
    font-size: 10px;
}

.size12 {
    font-size: 12px;
    line-height: 16px;
    color: #8F9BB3;
    font-weight: 500;
}

.size13 {
    font-size: 13px;
}

.size14 {
    font-size: 14px;
}

.size15 {
    font-size: 15px;
    line-height: 24px;
}

.size16 {
    font-size: 16px;
    line-height: 20px;
    color: #2E3A59;
}

.size18 {
    font-size: 18px;
}

.size22 {
    font-size: 22px;
}

.size24 {
    font-size: 24px;
}

.size26 {
    font-size: 26px;
}

.size30 {
    font-size: 30px;
}

.size32 {
    font-size: 32px;
}

.size36 {
    font-size: 36px;
}

.color-black {
    color: #000000;
}

.color-primary {
    color: var(--colorPrimary)
}

.color-text-menu {
    color: var(--colorTextMenu)
}

.color-text-secondary {
    color: #222B45
}

.text-link {
    cursor: pointer;
    color: var(--colorPrimary)
}

.text-idps {
    font-family: Roboto, sans-serif;
    margin-left: 6.7%;
    font-style: normal;
    font-weight: 500;
    font-size: 19.7112px;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.54)
}

.separator {
    border: 1px solid #E4E9F2;
    height: 0;
}

.logo-login {
    display: block;
    max-width: 300px;
    max-height: 200px;
}

.center {
    margin-left: auto !important;
    margin-right: auto !important;
}

.right {
    margin-left: auto !important;
    margin-right: 0 !important;
}

.left {
    margin-left: 0 !important;
    margin-right: auto !important;
}

.card {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.background {
    position: absolute;
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
    background: #F3F7F9;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto
}

.margin-top {
    margin-top: 35px;
}

.changeColor {
    fill: var(--colorPrimary);
    color: var(--colorPrimary);
}

.home-buttons {
    cursor: pointer;
    display: flex;
    width: 259px;
    height: 109px;
    background: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
    align-items: center;
    border: none;
    margin-right: auto !important;
    margin-left: auto !important;
    color: black;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 15px;
}

.unstyled-button {
    background: transparent;
    border: none;
    cursor: pointer;
    vertical-align: middle;
}

.unstyled-button:hover {
    background: transparent;
    color: var(--colorPrimary);
}

.border-button, .border-button:focus{
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    background: var(--colorPrimaryOpacity);
    border: 1px solid var(--colorPrimary);
    border-radius: 8px;
    color: var(--colorPrimary);
}

.border-button:hover, .border-button:active{
    background-color: var(--colorPrimary);
    border: none;
    color: var(--colorTextPrimary);
}

.border-secondary-button, .border-secondary-button:focus{
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    background: var(--colorPrimaryOpacity);
    border: 1px solid var(--colorSecondary);
    border-radius: 8px;
    color: var(--colorSecondary);
}

.border-secondary-button:hover, .border-secondary-button:active{
    background-color: var(--colorSecondary);
    border: 1px solid transparent;
    color: var(--colorTextSecondary);
}

.center-vertical-profile {
    display: flex;
    align-items: center;
}

.center-vertical{
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
}

.dropdown-item {
    height: 40px;
    display: flex;
    align-items: center;
}

.dropdown-button {
    font-size: 12px;
    font-weight: 700;
    display: flex;
    height: 32px;
    background: #EDF1F7;
    border: 1px solid transparent;
    border-radius: 8px;
    align-items: center;
    padding-left: 13px;
    color: #222B45;
}

.dropdown-button:hover {
    color: var(--colorPrimary);
    border: 1px solid;
}

.tag-info {
    height: 30px;
    background: var(--colorPrimaryOpacity);
    border: 1px solid var(--colorPrimary);
    border-radius: 25px;
    color: var(--colorPrimary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.tag-info-text {
    font-size: 15px;
    filter: brightness(0.6);
}

.height50
{
    height: 50px;
}

.height69
{
    height: 69px;
    background:#F7F9FC;
}

.new-card{
    background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 23px;
}

.footer-sign {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding-top: 10px;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1)
}

.footer-sign1 {
    width: 100%;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1)
}

.card-commom-info{
    padding: 23px 36px 21px 20px;
    background: var(--colorPrimaryOpacity);
    border: 1px solid var(--colorPrimary);
    border-radius: 23px;
    display: flex;
    filter: brightness(0.8);
}
 