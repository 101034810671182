@font-face {
  font-family: "NetcoIcon";
  src: url("../fonts/IconosNetcoSingerFont-Regular.eot");
  src: url("../fonts/IconosNetcoSingerFont-Regular.woff") format("woff"),
  url("../fonts/IconosNetcoSingerFont-Regular.otf") format("opentype"),
  url("../fonts/IconosNetcoSingerFont-Regular.ttf") format("ttf");
}

.netco-icon {
  font-family: NetcoIcon, Arial, serif;
  font-size: 20px;
  margin-left: -5px;
  font-style: normal;
}

.new-netco-icon {
  align-self: center;
  margin-right: 10.5px;
  width: 24px;
}

.submenu-icon {
  width: 18px;
  height: 18px;
  margin-left: 52px !important;
}

.submenu-only-icons {
  width: 18px;
  height: 18px;
  margin-left: 20px;
}

.idps-icon {
  width: 25.34px;
  height: 25.34px;
}

.shortcuts-icon{
  margin-right: 10.5px;
  width: 20px;
  height: 20px;
  align-self: center;
}