@import "customization.css";

.ant-typography,
.ant-col,
.ant-input,
.ant-row,
.ant-btn,
.ant-dropdown,
.dropdown-button,
.button
{
    font-family: "IBM Plex Sans", serif;
}

.ant-typography
{
    font-size:15px;
}

textarea {
    resize: none;
}

.ant-switch-checked {
    background: var(--colorPrimary) !important;
}

.ant-collapse-content {
    background-color: transparent !important;
    border-top: none !important;
}

.ant-collapse>.ant-collapse-item {
    border-bottom: none !important;
}

.ant-notification-notice-icon {
    position: absolute !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin-left: 16px !important;
}

.ant-notification-close-x {
    color: #FFFFFF !important;
}

.ant-notification-notice-message,
.ant-notification-notice-description {
    font-family: 'IBM Plex Sans', 'serif' !important;
    font-style: normal !important;
    ;
    font-weight: 400 !important;
    ;
    margin-left: 55px !important;
}

.ant-notification-notice-message {
    color: #FFFFFF !important;
    font-size: 15px !important;
}

.ant-alert-message {
    color: #8F9BB3 !important;
}

.ant-card-cover {
    margin-right: 0;
    margin-left: 0;
}



.no-icon .ant-form-item-children-icon {
    display: none;
}

.ant-dropdown .bg-white {
    background-color: #FFFFFF;
    border: 1px solid lightgray;
}

.ant-card-head,
.ant-table-title {
    border: none !important;
}


/*CARD*/
.ant-card-body .title-info .title {
    color: #8a8a8a
}

.ant-card-body .title-info .info {
    background-color: #f8f8f8;
    color: #9a9a9a;
    border: none;
    font-size: 15px;
}

/*----------------------*/

.ant-checkbox-wrapper {
    line-height: 20px;
}


.ant-btn.ant-btn-primary {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: var(--colorPrimary);
    border: none;
    color: var(--colorTextPrimary);
    border-radius: 8px;
    height: 32px;
}

.ant-btn.ant-btn-ghost {
    font-size: 12px !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    height: 32px;
    background-color: rgba(143, 155, 179, 0.08);
    border: 1px solid #8F9BB3;
    color: #8F9BB3;
    border-radius: 8px;
}

.ant-btn.ant-btn-ghost:hover {
    background-color: rgba(143, 155, 179, 0.08);
    border: 1px solid var(--colorPrimary);
    color: var(--colorPrimary);
    border-radius: 8px;
}

.ant-btn.btn-secondary {
    border-radius: 20px;
}

.ant-legacy-form-item {
    margin: 0;
}

.ant-menu-horizontal>.ant-menu-item:hover {
    color: var(--colorPrimary) !important;
    border-bottom: 2px solid var(--colorPrimary) !important;
}

.ant-menu-horizontal {
    color: var(--colorTextPanels) !important;
    border-bottom: 2px solid transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #514e5c;
}

.ant-row,
.ant-row:after,
.ant-row:before {
    display: flex;
}

.steps-content {
    width: 100%;
}

.ant-legacy-form-item-control-wrapper {
    flex: 100%;
}

.flexRight {
    justify-content: flex-end;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px!important;
}

.ant-table-wrapper {
    width: 100%;
}

.ant-picker-now-btn {
    display: none;
}


.netcoConfig .ant-legacy-form-item-control {
    line-height: 22px;
}

/*31032021*/
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {

    margin-right: 0 !important;
    margin-left: 0 !important;
}

.ant-menu-horizontal {
    border-bottom: 1px solid transparent !important;
}

/*TABS*/


.ant-tabs-tab {
    color: var(--colorTextPanels) !important;
    border-radius: 0 0 4px 4px !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
    overflow: initial !important;
}

.tabsContainer .ant-tabs-tab-active {
    border-left: 5px solid var(--colorPrimary) !important;
}

.tabsContainer .ant-tabs-ink-bar {
    display: none !important;
}

.signerBorder {
    border: 2px solid var(--colorPrimary);
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background: var(--colorMenu) !important;
}

.ant-menu-submenu-active {
    color: var(--colorTextMenu) !important;
}

.profileOptions {
    margin: 0;
    font-weight: 700;
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--colorPrimary) !important;
}

.ant-radio-checked .ant-radio-inner:after {
    background-color: var(--colorPrimary) !important;
}

.ant-radio:hover .ant-radio-inner {
    border-color: var(--colorPrimary) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--colorPrimary) !important;
    border-color: var(--colorPrimary) !important;
}

.ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--colorPrimary) !important;
}

.ant-checkbox:focus-within .ant-checkbox-inner {
    border-color: var(--colorPrimary) !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: var(--colorPrimary) !important;
    border-color: var(--colorPrimary) !important;
}

.ant-checkbox{
    top: -1px !important;
}

.divider-buttons {
    background: var(--colorPrimary) !important;
    display: inline-block;
    width: 2px;
    vertical-align: middle;
}

.button-pressed {
    background: white !important;
    border: 1px solid var(--colorPrimary) !important;
    color: var(--colorPrimary) !important;
}

.button-disabled {
    opacity: 0.5;
    color: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.button-enabled {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.border-image {
    border: 2px solid var(--colorPrimary) !important;
}

.button-enabled:hover {
    background-color: var(--colorPrimary) !important;
    border: none;
    color: #FFFFFF;
    border-radius: 20px;
    transform: translate(-1px, -1px);
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.3);
}

.handle-enabled {
    background-color: var(--colorPrimary) !important;
}

.btn-secondary {
    color: var(--colorTextSecondary) !important;
    background: var(--colorSecondary) !important;
    border-color: var(--colorSecondary) !important;
}

.btn-secondary:hover {
    color: var(--colorTextSecondary) !important;
    background: var(--colorSecondary) !important;
    border-color: var(--colorSecondary) !important;
    opacity: 0.8 !important;
}


.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--colorPrimary) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--colorPanels) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--colorPrimary) !important;
    box-shadow: 0 0 0 0.3px var(--colorPrimary)
}

.ant-pagination-item,
.ant-pagination-jump-next {
    background-color: #EDF1F7 !important;
    margin-right: 0 !important;
    border: none !important;
    border-right: 1px solid #d9d9d9 !important;
}

.ant-pagination-item-active {
    border: 1px solid var(--colorPrimary) !important;
    background-color: var(--colorPrimary) !important;
}

.ant-pagination-item-active a {
    color: white !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: var(--colorPrimary) !important;
}

.ant-pagination-item a:hover,
.ant-pagination-item-link:hover {
    color: var(--colorPrimary) !important;
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--colorPrimary) !important;
}



.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a,
.ant-menu-dark .ant-menu-item>span>a {
    font-weight: 400;
}

.ant-menu-dark.ant-menu-vertical .ant-menu-item,.ant-menu-inline .ant-menu-item {
    color: var(--colorTextMenu) !important;
    padding-left: 34px;
    height: 41px;
    font-size: 15px;
    font-family: 'IBM Plex Sans', 'serif';
    font-style: normal;
    align-items: center;
}
.ant-menu-submenu, .ant-menu-submenu-vertical, .ant-menu-submenu-inline
{
    color: var(--colorTextMenu) !important;
    height: 41px;
    font-size: 15px;
    font-family: 'IBM Plex Sans', 'serif';
    font-style: normal;
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--colorPrimary);
}

.ant-menu-dark.ant-menu-vertical, .ant-menu-dark.ant-menu-inline {

    background-color: transparent !important;

}

.tabs-container .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 7px solid var(--colorPrimary) !important;
}

.tabs-container .ant-tabs-nav {
    border-bottom: 2px solid #EDF1F7 !important;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
    border-bottom: none !important;
}

.ant-table-thead>tr>th {
    font-weight: 400 !important;
    font-family: 'IBM Plex Sans', 'serif' !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #222B45 !important;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
    border: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 35px !important;
}

.ant-table-thead>tr.ant-table-row-selected:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr.ant-table-row-selected:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: var(--colorPanels) !important;

}

.ant-table-thead>tr>th {
    border-bottom: none !important;
}

.ant-table-small .ant-table-thead>tr>th {
    background-color: white !important;
}

.ant-menu-horizontal>.ant-menu-item a {
    color: var(--colorTextPanels) !important;
}

.react-pdf__message--error {
    position: absolute;
    width: 150px
}

.ant-input-group>.ant-input:first-child {
    height: 32px;
}

.ant-table-thead>tr>th {
    font-size: 13px;
}

.ant-table-tbody>tr>td {
    font-size: 15px;
}

.ant-table-wrapper {
    margin-bottom: 32px;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-dropdown-menu-item:hover {
    background: var(--colorPrimaryOpacity) !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: var(--colorPrimaryOpacity) !important;

}

.rc-color-picker-trigger {
    box-shadow: none !important;
}



.ant-input-password-icon {
    margin-right: 0;
}


.ant-input-prefix {
    padding-top: 14px;
    position: absolute;
    z-index: 2;
    font-size: 14px;
    padding-left: 11px;
}

.ant-input-suffix {
    align-self: center;
    position: absolute;
    z-index: 2;
    font-size: 14px;
    right: 0;
    margin-right: 16px;
}

.netcoIconPadding .ant-input-prefix {
    padding-top: 5px;
}





.ant-input-affix-wrapper {
    padding: 0;
    border-radius: 12px;
    border: none;
}

.ant-input-affix-wrapper>input.ant-input {
    padding-left: 16px;
    font-weight: 400;
    size: 15px;
    border: 1px solid #E4E9F2;
    background: #FFFFFF;
}

.ant-input:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--colorPrimary) !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: none;
}

.ant-input:focus {
    box-shadow: 0 0 0 3px var(--colorPrimaryOpacity);
    border-color: var(--colorPrimary) !important;
}

.ant-input-affix-wrapper:focus {
    border: none;
    box-shadow: 0 0 0 2px var(--colorPrimaryOpacity);

}

.ant-input-focused {
    box-shadow: 0 0 0 2px var(--colorPrimaryOpacity);
}

.ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px var(--colorPrimaryOpacity);
}


.ant-input-focused:not(.ant-input-affix-wrapper-disabled) {
    border-color: var(--colorPrimary) !important;
}

.ant-input-affix-wrapper-focused:not(.ant-input-affix-wrapper-disabled) {
    border: none;
}

.ant-input {
    border-radius: 12px!important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 3px var(--colorPrimaryOpacity) !important;
    border-color: var(--colorPrimary) !important;
}

.ant-menu-item-group-title
{
    line-height: 1.5715;
    padding-left: 34px!important;
}

.ant-menu-submenu-inline, .ant-menu-submenu-open
{
    display: inline!important;
}

.unstyled-button.ant-btn[disabled], .unstyled-button.ant-btn[disabled]:hover, .unstyled-button.ant-btn[disabled]:focus, .unstyled-button.ant-btn[disabled]:active{
    background-color: transparent;
}

.unstyled-button.ant-btn:hover, .unstyled-button.ant-btn:focus, .unstyled-button.ant-btn:active{
    background-color: transparent;
}

.ant-steps-item-container
{
    margin-bottom: 80px;
}

:where(.css-dev-only-do-not-override-17sses9).ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    background-color: #ffffff00 !important;
}

:where(.css-17sses9).ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    background-color: #ffffff00 !important;
}