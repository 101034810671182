.ant-modal .ant-modal-content,
.ant-modal .ant-modal-content .ant-modal-header {
  border-radius: 15px;
}

.ant-modal .ant-modal-content .ant-modal-header {
  border: none;
  color: #8a8a8a;
  font-size: 20px;
}

.ant-modal .ant-modal-content .ant-modal-title {
  color: #8a8a8a;
  font-size: 20px;
}

.ant-modal .ant-modal-body {
  padding-top: 0;
}

.ant-modal .ant-modal-body label {
  color: #8a8a8a;
}

.canvasBorder {
  background: rgba(143, 155, 179, 0.08);
  border: 0.389552px solid #E4E9F2;
  border-radius: 6.23284px;
  /* width:100%;
  height: 100%; */
  /*max-width: 720px;*/
}