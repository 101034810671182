.collapse-verify{
    background: #C5CEE033 !important;
    border: none !important;
    border-radius: 20px !important;
    padding: 10px 20px !important;
    box-shadow: -4px 0px 4px 0px rgba(0, 0, 0, 0.15) !important;

}
.flex-col {
    display: flex;
    padding: 5px;
}

.flex-responsive {
    flex-grow: 1;
    flex-basis: 200px; 
}