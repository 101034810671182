.ant-form-item {
    color: #8F9BB3;
    font-weight: 400;
    font-family: "IBM Plex Sans";
    font-size: 13px;
    line-height: 24px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 12px;
}

.ant-form label  {
    font-size: 13px;
    color: #222B45;
    align-items: center;
}

.ant-form-item-control-input-content{
    font-size: 15px;
    color: #8F9BB3;
    line-height: 20px;
    font-family: "IBM Plex Sans";
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    height: 40px;
}

.ant-input {
    height: 40px;
    color:#8F9BB3;
    font-size: 15px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item{
    margin-top: auto;
    margin-bottom: auto;
}

.ant-select {
    color:#8F9BB3;
    font-size: 15px;
}

.margin-input{
    margin-top: 8px;
    margin-bottom: 13px;
}

.margin-top-check{
    margin-top: 14px;
}

.save-button{
    width: 103px;
    height: 40px;
    position: absolute;
    left: 33px;
    bottom: 23px;
}