.management-card{
    margin: 0 20px 20px 20px;
    display: flex;
    height: 100%
}

.card-info {
    width: 290px;
    display: block;
    text-align: center;
}

.ant-table-title {
    padding: 0 !important;
}

.icon-actions-card {
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 10;
}

.card-file {
    height: 100%;
    padding: 10px;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
}

.card-selected {
    background: rgba(143, 155, 179, 0.08);
}

.ant-checkbox{
    top: 0.2em !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left:0px !important
}

.ant-collapse-content > .ant-collapse-content-box{
    padding-left: 0px !important
}