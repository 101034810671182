/**  =====================
  Spinning
==========================  **/
@import '../../Helpers/customization.css';
.loading-container {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

#circle {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55px;
  height: 165px;
}

/*.loader {
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  border: 8px transparent;
  border-top: 8px solid #1b8bf9;
  border-radius: 50%;
  animation: rotate 5s linear infinite;
}*/

.loading-spin {
  color:var(--colorPrimary)
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}