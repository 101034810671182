
.image-container {
  align-items: center;
  display: flex;
  width: 85%;
  height: 80%;
  float: left;
  margin: 15px 10px 10px 37px;
  text-align: center;
}

.preview-container {
  height: 163px;
  width: 100%;
}

.placeholder-preview {
  cursor: pointer;
  background: #FFFFFF !important;
  color: #bdc3c5 !important;
  border: 0px solid #efefef;
  border-radius: 10px;
}

.placeholder-preview,
.render-preview {
  text-align: center;
  background-color: #efebeb;
  height: 161px;
  width: 100%;
}

.render-preview {
  padding: 10px;
  height: 50px;
}

.upload-container {
  cursor: pointer;
  height: 300px;
}

.file-preview {
  text-align: center;
  border: 1px dashed gray;
  margin-bottom: .5rem;
  background-color: white;
}

