.new-notification-success, .new-notification-error
{
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 12px;
}

.new-notification-success
{
    background: #00D68F;
}
.new-notification-error
{
    background: #FF3D71;
}
.new-notification-warning
{
    background: #F3AD3E;
}