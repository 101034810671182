@font-face {
    font-family: "Brush Script MT";
    src: url("../fonts/brush-script-mt-kursiv.woff") format("woff")
}
@font-face {
    font-family: "Indie Flower";
    src: url("../fonts/IndieFlower-Regular.woff") format("woff")
}
@font-face {
    font-family: "Josefin Slab Thin";
    src: url("../fonts/JosefinSlab-Italic-VariableFont_wght.woff") format("woff")
}
@font-face {
    font-family: "Lobster";
    src: url("../fonts/Lobster-Regular.woff") format("woff")
}
@font-face {
    font-family: "IBM Plex Sans";
    src: url("../fonts/IBMPlexSans-Regular.ttf") format("truetype")
}