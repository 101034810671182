@import "../../../Helpers/customization.css";

.ant-dropdown-menu.private-header-menu {
  min-width: 150px
}

.disabled-link {
  pointer-events: none;
}

.menuBar {
  padding: 0 20px;
  border: none;
  background-color: #FFFFFF;
}

@media (max-width: 767px) {
  .menuBar {
    padding: 0 20px;
    border: none;
    background-color: #3A3747;
  }
}

.menuBar .header-title, .menuBar .header-title:hover {
  color: var(--colorTextPanels);
  cursor: default;
}

.menuBar .ant-menu .ant-menu-item.ant-menu-item-selected {
  color: rgb(107, 154, 180) !important;
}

.logo {
  width: 150px;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.rightMenu, .rightMenu ul {
  background-color: transparent;
}

.rightMenu, .rightMenu ul li {
  float: right;
  margin: 5px;
  border-radius: 3px;
}

.barsMenu, .barsMenu:hover {
  float: right;
  height: 32px;
  padding: 6px;
  margin: 8px;
  display: none;
  background: #3A3747;
  cursor: pointer;
  color: #FFFFFF;
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item, .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }
  
  .leftMenu, .rightMenu {
    display: none;
  }
  
  .logo a {
    margin-left: -20px;
  }
  
  .menuCon .ant-menu-item, .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }
  
  .logo a {
    padding: 10px 20px;
  }
}