@import '../../components/Helpers/customization.css';
.ant-btn {
  white-space: normal;
  line-height: 15px;
  margin-bottom: 10px;
}

.rounded {
  border-radius: 20px;
  width: 50%;
  height: 40px
}

.rounded-sm {
  border-radius: 20px;
  width: 30%;
  min-width:76px;
  height: 30px
}

.ant-btn.ant-btn-primary {
  background-color: var(--colorPrimary) !important;
  border: none;
  color: var(--colorTextPrimary);
  border-radius: 20px;
}

.ant-btn.ant-btn-primary:hover, .ant-btn.ant-btn-primary:active, .ant-btn.ant-btn-primary:focus {
  border-color: var(--colorPrimary) !important;
  background:  #fff !important;
  color: var(--colorPrimary) !important;
  border: 1px solid var(--colorPrimary)!important;
}

.ant-btn-light-primary {
  color: var(--colorText);
  background: #fff;
}

.ant-btn.ant-btn:focus, .ant-btn.ant-btn:hover {
  color: var(--colorPrimary);
  border-color: var(--colorPrimary) ;
  background: #fff;
}

.middle-blue {
  background-color: #4894BF;
  border: none;
  color: #FFFFFF;
}

.dark-blue {
  background-color: #306380;
  border: none;
  color: #FFFFFF;
}

.idps {
  border-radius: 2.82px;
  gap: 14.08px;
  border: 1px solid #EDF1F7;
  box-shadow: 0px 0px 1.40794px rgba(0, 0, 0, 0.084), 0px 1.40794px 1.40794px rgba(0, 0, 0, 0.168);
  width: 100%;
  height: 56.32px
}