.profile-row-card{
  row-gap: 18px !important;
  text-align: center; 
}

.profile-card{
  padding-top: 43px;
  width: 284px;
  height: 142px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 23px;
}
