.profile-info .ant-list.ant-list-split .ant-list-item {
  padding: 0;
}

.profile-info .ant-list.ant-list-split .ant-list-item-meta-title {
  font-weight: bold;
}

.profile-info .ant-row {
  padding: 0px;
}


/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

/*@media (min-width: 768px) and (max-width: 1281px) {
  .profile-info .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab {
    width: 25%;
  }
}*/

.profile-options .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  line-height: 1.5;
}

.react-resizable {
  position: relative;
  display: inline-block;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  padding: 0 3px 3px 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+') no-repeat bottom right;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

/*TABS*/


.tabs-container .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  background-color: #EFF9FF;
  color: #83ABC0;
  max-width: 190px;
}
.bigContainer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab{
  background-color: #EFF9FF;
  color: #83ABC0;
  max-width: 230px;
}

