@import "../../resources/css/buttons.css";
@import "../../resources/css/icons.css";
@import "../../resources/css/fonts.css";
@import "../../resources/css/modal.css";
@import "../../resources/css/components.css";

body,
html {
  font-family:IBM Plex Sans;
  font-weight: 400;
  font-style: normal;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.float-right {
  float: right;
}

.p-20 {
  padding: 0 20px 20px;
}

.m-0 {
  margin: 0;
}

.ant-form-item {
  margin-bottom: 12px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15{
  margin-top: 15px
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-25 {
  margin-top: 25px;
}

.ml-0{
  margin-left: 0px !important;
}

.mb-20{
  margin-bottom: 20px;
}

.mb-70{
  margin-bottom: 70px;
}

.mb-30{
  margin-bottom: 30px;
}

.text-center {
  text-align: center;
}

.pt-50 {
  padding-top: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.bg-white {
  background-color: #FFFFFF;
}

.icon-font {
  color: rgba(0,0,0,.25);
}

.ant-layout-sider-zero-width-trigger {
  z-index: 99999;
  position: absolute;
  top: 0;
  right: -36px;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #3a3747;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.ant-alert.ant-alert-no-icon {
  max-height: 50px;
  padding: 8px 15px;
}

.ant-table-small > .ant-table-content .ant-table-header {
  overflow: hidden;
  background-color: #fff;
  height: 75px;
}


@media (min-width: 320px) and (max-width: 767px) {
  .mobile-hide {
    display: none;
  }

  .mobile-show {
    font-size: 10px;
  }

  .menuBar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
  }

  /*.ant-layout-content {
    padding-top: 40px;
  }*/

}

.link, .link:hover {
  background: transparent;
  border: none;
  color: var(--colorPrimary);
  filter: brightness(0.8);
  cursor: pointer;
}

.link10 {
  color: var(--colorPrimary);
  filter: brightness(0.8);
  cursor: pointer;
  height: 10px;
}

.template-tabs .card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: 0;
}

.ant-layout {
  background-color: #FFFFFF;
}

a{
  color: var(--colorPrimary);
}

a:hover{
  color:var(--colorPrimary) ;
}

.flex{
  display: flex;
}

