.ant-layout
{
  margin-left:0!important;
}
.sider-logo {
  max-width: 166px;
  max-height: 166px;
}

.ant-layout-sider, .ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
  background: var(--colorMenu)!important;
}

.ant-layout-sider .ant-menu li.ant-menu-item.ant-menu-item-selected.ant-menu-item-disabled,
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: var(--colorTextMenu) !important;
  font-weight: 700 !important;
  cursor: default;
  background: var(--colorPrimaryOpacity)!important;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span, .ant-menu-dark .ant-menu-item-selected .anticon + span, .ant-menu-item-group-title
{
  color: var(--colorTextMenu) !important;
  font-weight: 700 !important;
  cursor: default;
}

.ant-menu-item.pos-bottom{
  position: absolute!important;
  width: 100%;
}

.pos-bottom192{
  bottom:192px;
}

.pos-bottom141{
  bottom:141px;
}

.pos-bottom90{
  bottom:90px;
}

.menu{
  text-align: center;
}

.ant-drawer-body {
  background: var(--colorMenu);
}

.ant-drawer-header{
  background: var(--colorMenu);
}

.sider-menu-vertical{
  padding-top: 36px;
  position: fixed;
  min-height: 100%;
  /* overflow: auto; */
  top: 0;
  bottom: 0
}

.icon-menu path {
  fill: var(--colorPrimary);
 }

 .ant-menu-dark.ant-menu-inline .ant-menu-item {
  padding-left: 0 !important;
 }
 .ant-menu-dark .ant-menu-submenu-title {
  padding-left: 0 !important;
 }
.ant-menu-dark.ant-menu-vertical .ant-menu-item, .ant-menu-submenu-title{
  display: flex;
}

.ant-menu-submenu-active .ant-menu-submenu-title
{
  color: var(--colorTextMenu) !important;
  cursor: default;
  background: var(--colorPrimaryOpacity)!important;
}

.ant-menu-submenu .ant-menu-submenu-title
{
  color: var(--colorTextMenu) !important;
}

.div-footer{
  position: absolute;
  bottom: 0;
  padding-left: 24px;
  padding-bottom: 25px;
  text-align: left;
  background-color: var(--colorMenu);
  padding-top: 10px;
}

.margin-icons{
  margin-left: 24px;
}

.margin-onlyIcons{
  margin-left: 16px;
  margin-right: 0;
}